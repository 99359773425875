import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: Boolean,
            default: false
        },
        space: {
            type: Object,
            default: () => { }
        }
    },
    setup(props) {
        return { checked: props.value };
    }
});
