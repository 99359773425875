import { computed, defineComponent, ref, watch, onMounted, reactive } from '@vue/composition-api';
import VenueSelector from '@/components/VenueSelector.vue';
import EmptyView from '@/components/EmptyView.vue';
import ServicesTable from '@/Services/components/ServicesTable.vue';
import { withAuthQueryHook } from '@/util/hooks';
import { useGetVenuesQuery } from '@/generated-types/graphql.types';
export default defineComponent({
    components: {
        EmptyView,
        ServicesTable,
        VenueSelector
    },
    setup(_, context) {
        const { root } = context;
        const selectedVenueId = ref(0);
        const getVenueQueryOptions = reactive({ clientId: 'legacy', enabled: false, fetchPolicy: 'no-cache' });
        const { result: getAllVenues, refetch } = withAuthQueryHook(useGetVenuesQuery)({}, getVenueQueryOptions);
        const venues = computed(() => getAllVenues.value?.viewerApiKey?.venuesGet);
        watch(() => venues.value, () => {
            if (venues.value && venues.value.length) {
                selectedVenueId.value = venues.value[0]?.venueId || 0;
                globalThis.$router.replace({
                    params: { venueId: selectedVenueId.value },
                    query: root.$route.query
                });
            }
        }, { immediate: true });
        const onVenueSelected = (_venueId) => {
            selectedVenueId.value = _venueId;
            if (selectedVenueId.value !== +root.$route.params.venueId) {
                globalThis.$router.replace({
                    params: { venueId: selectedVenueId.value },
                    query: root.$route.query
                });
            }
        };
        onMounted(() => {
            getVenueQueryOptions.enabled = true;
            refetch();
        });
        return {
            selectedVenueId,
            venues,
            onVenueSelected
        };
    }
});
