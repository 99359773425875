import { defineComponent, computed } from '@vue/composition-api';
import getOrganisation from '@/api/organisation/get';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        venueId: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const venueWizardStep = computed(() => globalThis.$store.state.$_vendor.venueWizard);
        const cleanVenueData = globalThis.$store._mutations['$_vendor/CLEAN_VENUE_DATA'][0];
        const checkOrganization = async () => {
            const organisation = await getOrganisation();
            if (!Object.keys(await organisation.json()).length) {
                globalThis.$router.push(useGetLocalizedPath('vendor/onboarding/organisation-details'));
                return null;
            }
            return organisation;
        };
        const createSpace = () => {
            cleanVenueData();
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.MAIN,
                params: { venueId: props.venueId }
            });
        };
        const createVenue = async () => {
            try {
                const organisation = await checkOrganization();
                if (organisation?.status === 200) {
                    globalThis.$router.push({
                        name: ROUTE_LIST_NAMES.ONBOARDING.VENUE[venueWizardStep.value]
                    });
                }
            }
            catch (error) {
                console.log('Error in venue creation', error);
            }
        };
        return {
            createSpace,
            createVenue
        };
    }
});
