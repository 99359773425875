import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { pickBy, keys, map } from 'lodash';
import ListItem from '@/uikit/ListItem.vue';
import { VAT_RATES } from '@/shared/const';
import Toggle, { Option as ToggleOption } from '@/uikit/Toggle.vue';
import { withAuthQueryHook, withAuthMutationHook } from '@/util/hooks';
import FormVerticalRadio from '@/shared/components/form/FormVerticalRadio.vue';
import PriceInput from '@/uikit/PriceInput.vue';
import Popover from '@/components/Popover.vue';
import { useGetSpacesByVenueIdQuery, useUpdateServiceMutation, SpaceOrderItemType } from '@/generated-types/graphql.types';
import SpaceCard from '@/Services/components/SpaceCard.vue';
import ListDropdown from '@/uikit/ListDropdown.vue';
import { IServiceTableRow } from '@/Services/services.types';
const getSpaces = withAuthQueryHook(useGetSpacesByVenueIdQuery);
const updateService = withAuthMutationHook(useUpdateServiceMutation);
const labelId = Number(process.env.VUE_APP_LABEL_ID);
export default defineComponent({
    components: {
        ListDropdown,
        FormVerticalRadio,
        ListItem,
        Popover,
        PriceInput,
        SpaceCard,
        Toggle,
        ToggleOption
    },
    props: {
        value: {
            type: IServiceTableRow,
            required: true
        },
        venueId: {
            type: Number,
            default: 0
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const service = ref({
            ...props.value,
            selectedLimitOption: props.value.is_inventory_enabled ? 1 : 0
        });
        const availability = ref([]);
        const enabled = ref(props.value.is_enabled);
        const errors = ref([]);
        const vatRates = computed(() => [
            {
                label: root.$i18n.t('onboarding.organisation_details.select_option_vat_21'),
                value: VAT_RATES.TWENTY_ONE
            },
            {
                label: root.$i18n.t('onboarding.organisation_details.select_option_vat_9'),
                value: VAT_RATES.NINE
            },
            {
                label: root.$i18n.t('onboarding.organisation_details.select_option_vat_none'),
                value: VAT_RATES.NONE
            }
        ]);
        const rate = ref(props.value.vat_rate);
        const isMandatoryServiceFeatureEnabled = Number(process.env.VUE_APP_ENABLE_MANDATORY_SERVICES);
        const isMandatory = ref(props.value.is_mandatory);
        const { result: spaces } = getSpaces({ venueId: props.venueId }, {
            clientId: 'legacy',
            errorPolicy: 'all'
        });
        const { mutate } = updateService();
        const updateAvailability = id => {
            availability.value[id] = !availability.value[id];
        };
        watch(() => spaces?.value?.viewerApiKey?.getSpacesByVenueId, val => {
            if (val) {
                const spacesIds = val.map(s => s?.spaceId);
                const relatedIds = props.value.related_spaces.map(s => s.space_id);
                availability.value = spacesIds.reduce((acc, cur) => ({
                    ...acc,
                    [cur]: props.value.is_enabled ? relatedIds.includes(cur) : true
                }), {});
            }
        }, { immediate: true });
        const spaceCount = computed(() => map(keys(pickBy(availability.value)), Number));
        const updateStatus = async () => {
            enabled.value = !enabled.value;
        };
        const getError = field => {
            if (errors.value.includes(field)) {
                switch (field) {
                    case 'vatRate':
                        return root.$i18n.t('onboarding.organisation_details.errors.vat_rate_empty');
                    case 'selectSpace':
                        return root.$i18n.t('inventory_management.modal.errors.space_not_selected');
                }
            }
        };
        // Updating service field value from child components
        const updateServiceField = (field, value) => {
            if (field === 'is_inventory_enabled') {
                service.value[field] = value === 1;
                return;
            }
            service.value[field] = value;
        };
        const onInput = (value) => {
            rate.value = value;
        };
        const submit = async () => {
            if (rate?.value === null &&
                service?.value.price &&
                service.value.price > 0) {
                errors.value.push('vatRate');
                return;
            }
            if (Object.values(availability.value).every(value => !value) &&
                enabled.value) {
                errors.value.push('selectSpace');
                return;
            }
            const inventory_service = {
                price: service.value.price || 0,
                description: service.value.description,
                is_inventory_enabled: service.value.is_inventory_enabled,
                max_inventory: service.value.is_inventory_enabled
                    ? service.value.max_inventory
                    : 0,
                is_enabled: enabled.value,
                is_mandatory: isMandatory.value,
                vat_rate: rate.value,
                is_on_demand: true,
                order_type: service.value.venue_service_order_type
            };
            const space_ids = map(keys(pickBy(availability.value)), Number);
            const result = await mutate({
                venue_id: service.value.venue_id,
                system_service_id: service.value.system_service_id,
                inventory_service,
                label_id: labelId,
                space_ids
            }, {});
            if (result)
                emit('submit', result);
        };
        return {
            availability,
            enabled,
            errors,
            isMandatory,
            isMandatoryServiceFeatureEnabled,
            rate,
            service,
            spaceCount,
            spaces: computed(() => spaces.value?.viewerApiKey?.getSpacesByVenueId),
            vatRates,
            SpaceOrderItemType,
            getError,
            onInput,
            submit,
            updateAvailability,
            updateStatus,
            updateServiceField
        };
    }
});
